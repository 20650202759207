.feed {
  overflow-y: hidden;
  height: 500px;

  @media #{$lg-layout} {
    overflow-y: scroll;
  }

  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #606060;
  }

  &-item {
    display: list-item;
    table-layout: fixed;
    list-style-type: none;
    list-style-position: outside;
    padding: 0.5rem 0;
    font-size: 14px;

    &:first-child {
      padding-top: 0;
    }

    &-avatar {
      position: relative;
      display: inline-block;
      background-color: transparent;
      margin: 0;
      vertical-align: top;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 500rem;
    }

    &-content {
      display: inline-block;
      width: calc(100% - 3rem);
      padding: 0 0 0 0.7rem;
      vertical-align: top;
      line-height: 1.2rem;

      &__header {
        margin: 0;
        display: flex;
        justify-content: space-between;
        color: rgba(198, 201, 216, 0.25);

        span:first-child {
          color: rgba(198, 201, 216, 0.75);
          font-weight: 700;
        }
      }

      &__description {
        color: #fff;
      }
    }
  }
}

.icon-image {
  img {
    // max-width: 90%;
    max-height: 120px;
  }
}

.nft-slider {
  h1,
  p {
    font-size: 80px;
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    line-height: 90px;
  }

  p {
    font-size: 30px;
    margin-top: 3rem;
    font-weight: 600;
  }
}

.icon-table {
  &--red {
    font-size: 45px;
    font-weight: 400;
    color: var(--color-primary);
    text-align: center;
    justify-content: center;
    border: none;
    outline: none;
  }

  &--green {
    font-size: 45px;
    font-weight: 400;
    color: #59c98d;
    text-align: center;
    justify-content: center;
    border: none;
    outline: none;
  }
}

.table {
  border-bottom: 1px solid #353434;

  td,
  th {
    border-top: 1px solid #353434;
    vertical-align: middle;
  }

  h4 {
    color: rgba(198, 201, 216, 0.75);
    font-size: var(--font-size-b1);
    line-height: var(--line-height-b1);
    font-weight: 400;
  }

  p {
    color: rgba(198, 201, 216, 0.75);
  }
}
